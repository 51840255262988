//== Pagination
//
//##

$pagination-color:                     #fff !default;
$pagination-bg:                        $brand-primary !default;
$pagination-border:                    transparent !default;

$pagination-hover-color:               #fff !default;
$pagination-hover-bg:                  darken($brand-primary, 15%) !default;
$pagination-hover-border:              transparent !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 darken($brand-primary, 15%) !default;
$pagination-active-border:             transparent !default;

$pagination-disabled-color:            $gray-lighter !default;
$pagination-disabled-bg:               lighten($brand-primary, 15%) !default;
$pagination-disabled-border:           transparent !default;
